/* eslint-disable eqeqeq */

import React from 'react'
import IframeComm from "react-iframe-comm";
import { createNewWallet, poaSign, poaNormalSign, ethContractSign } from './Blockchain'
import { isValidAddress } from './RuleUtils'
import db from './model/db'
import { encryptMsg, decryptMsg } from './Utils'

import Box from '@material-ui/core/Box';

var height = document.documentElement.clientHeight


const Game = () => {
 
  var mainUrl = process.env.REACT_APP_EX_MAIN_URL;; // "https://ipeu-wallet.azurefd.net/"
  // document.cookie = "key=value; path=/; domain=.dae.club";
  document.cookie = "key=value; path=/; domain=ait.run";

  // const [openDappDialog, setOpenDappDialog] = React.useState(false);
  // const [dappUrl, setDappUrl] = React.useState("");

  const requestWebAuth = () => {
    var createCredentialDefaultArgs = {
      publicKey: {
          // Relying Party (a.k.a. - Service):
          rp: {
              name: "Acme"
          },
  
          // User:
          user: {
              id: new Uint8Array(16),
              name: "john.p.smith@example.com",
              displayName: "John P. Smith"
          },
  
          pubKeyCredParams: [{
              type: "public-key",
              alg: -7
          }],
  
          authenticatorSelection: {
            authenticatorAttachment: "platform",
          },

          attestation: "direct",
  
          timeout: 60000,
  
          challenge: new Uint8Array([ // must be a cryptographically random number sent from a server
              0x8C, 0x0A, 0x26, 0xFF, 0x22, 0x91, 0xC1, 0xE9, 0xB9, 0x4E, 0x2E, 0x17, 0x1A, 0x98, 0x6A, 0x73,
              0x71, 0x9D, 0x43, 0x48, 0xD5, 0xA7, 0x6A, 0x15, 0x7E, 0x38, 0x94, 0x52, 0x77, 0x97, 0x0F, 0xEF
          ]).buffer
        }
    };

    var getCredentialDefaultArgs = {
      publicKey: {
          timeout: 60000,
          // allowCredentials: [newCredential] // see below
          challenge: new Uint8Array([ // must be a cryptographically random number sent from a server
              0x79, 0x50, 0x68, 0x71, 0xDA, 0xEE, 0xEE, 0xB9, 0x94, 0xC3, 0xC2, 0x15, 0x67, 0x65, 0x26, 0x22,
              0xE3, 0xF3, 0xAB, 0x3B, 0x78, 0x2E, 0xD5, 0x6F, 0x81, 0x26, 0xE2, 0xA6, 0x01, 0x7D, 0x74, 0x50
          ]).buffer
      },
    };

    navigator.credentials.create(createCredentialDefaultArgs)
    .then((cred) => {
        console.log("NEW CREDENTIAL", cred);

        // normally the credential IDs available for an account would come from a server
        // but we can just copy them from above...
        var idList = [{
            id: cred.rawId,
            transports: ["platform"],
            type: "public-key"
        }];
        getCredentialDefaultArgs.publicKey.allowCredentials = idList;
        return navigator.credentials.get(getCredentialDefaultArgs);
    })
    .then((assertion) => {
        console.log("ASSERTION", assertion);
        alert("ASSERTION "+assertion)

    })
    .catch((err) => {
        console.log("ERROR", err);
        alert("err "+err)
    });
  }

  return (
    <React.Fragment>
      <Box style={{
        width: "100%",
        height: height,
        // backgroundColor:"#000000",
      }}>
       
        {/* <Box style={{
          width:'100%',
          position: 'fixed',
          height: height,
          top: 'auto',
        }}>
            
        </Box> */}
        
 
        <IframeComm
              attributes={{
                id: "iframe",
                src: mainUrl,
                position: 'fixed',
                width:'100%',
                top: 'auto',
                height: height,
              }}
              postMessageData={"browser hello iframe"}
              handleReady={()=>{
                if (window.opener) {
                  console.error("來自子視窗window.opener");
                  window.opener.postMessage('來自子視窗*的訊息', '*');
                  // window.opener.postMessage('來自子視窗dae.club的訊息', 'https://www.dae.club');
                  window.opener.postMessage('來自子視窗dae.club的訊息', 'https://ait.run');
                  window.opener.postMessage('來自子視窗/127.0.0.1的訊息', 'http://127.0.0.1:5500');
                  window.opener.postMessage('來自子視窗的訊息azurestaticapps', 'https://ashy-rock-0581de110.4.azurestaticapps.net'); // site-main
                  window.opener.postMessage('來自子視窗window.location.origin的訊息', window.location.origin);
                } else {
                  console.error("No opener window found");
                }
              }}
              handleReceiveMessage={(event)=>{
                if (event.data.method) {
                  var iframe = document.getElementById('iframe').contentWindow

                  // console.log("test99 onReceiveMessage "+JSON.stringify(event.data))

                  const request = event.data.data

                  switch (event.data.method) {
                    case "getWallet":
                      const poaAddress = localStorage.getItem('poaAddress_aqn')
                      if (poaAddress && isValidAddress(poaAddress)) {
                        iframe.postMessage({method:"returnWallet", data:poaAddress}, '*');
                      } else {
                        iframe.postMessage({method:"returnWallet", data:"EMPTY"}, '*');
                      }
                      break
                    case "getLocalStorage":
                      var localData = localStorage.getItem(request)
                      iframe.postMessage({method:"returnLocalStorage", data:localData}, '*');
                      break
                    case "getLocation":
                      var location = window.location.href
                      iframe.postMessage({method:"returnLocation", data:location}, '*');
                      break
                    case "encryptMsg":
                      var msg = encryptMsg(request.input, request.key)
                      iframe.postMessage({method:"returnEncryptMsg", data:msg}, '*');
                      break
                    case "decryptMsg":
                      var dmsg = decryptMsg(request.input, request.key)
                      iframe.postMessage({method:"returnDecryptMsg", data:dmsg}, '*');
                      break
                    case "getTgId":
                      iframe.postMessage({method:"returnTgId", data:"0"}, '*');
                      break
                    case "getAuthAddress":

                      db.table_wallet.where('chainType').anyOf(1).first((wallet)=>{            
                        if(wallet) {
                          iframe.postMessage({method:"returnAuthAddress", data:wallet.address}, '*');
                        } else {
                          iframe.postMessage({method:"returnAuthAddress", data:""}, '*');
                        }
                      })
                      
                      break
                    case "generatePky":
                      createNewWallet().then(ethAddress=>{
                        console.log('createNewWallet '+ethAddress)

                        iframe.postMessage({method:"returnAuthAddress", data:ethAddress}, '*');
                      }).catch((err)=>{
                        console.log("err "+err)
                      })
                      break
                    case "setPoaAddress":
                      localStorage.setItem('poaAddress_aqn', request);
                      break
                    case "signWalletData":
                      poaSign(request).then(signature=>{
                        if(signature == "") {
                          return
                        }
                        console.log('ethSign '+JSON.stringify(signature))

                        iframe.postMessage({method:"returnWalletSignature", data:signature}, '*');
                      }).catch((err)=>{
                        console.log("err "+err)
                      })
                      break
                    case "signEthWalletContractData":
                      ethContractSign(request.signData, request.toAddress).then(signature=>{
                        if(signature == "") {
                          return
                        }
                        // console.log('signEthWalletContractData '+JSON.stringify(signature))

                        iframe.postMessage({method:"returnEthWalletContractSignature", data:signature}, '*');
                      }).catch((err)=>{
                        console.log("err "+err)
                      })
                      break
                    case "signNormalData":
                      poaNormalSign(request.signData, request.toAddress).then(signature=>{
                        if(signature == "") {
                          return
                        }
                        // console.log('ethNormalSign '+JSON.stringify(signature))

                        iframe.postMessage({method:"returnNormalSignature", data:signature}, '*');
                      }).catch((err)=>{
                        console.log("err "+err)
                      })
                      break
                    case "launchDapp":
                      // setDappUrl(request)
                      // setOpenDappDialog(true)
                      break
                    case "closeDapp":
                      // setOpenDappDialog(false)
                      break
                    case "launchChat":
                      
                      break
                    case "appAuthorize":
                      requestWebAuth()
                      break
                    case "vote":
                      if (window.opener) {
                        let postVoteTransactResult = request
                        window.opener.postMessage(postVoteTransactResult, "*");
                        window.parent.postMessage(postVoteTransactResult, "*");
                        console.log('window.location.origin', window.location.origin)
                        // window.opener.postMessage('來自子視窗的訊息', 'https://www.dae.club');
                        window.opener.postMessage('來自子視窗的訊息', 'https://ait.run');
                        window.opener.postMessage('來自子視窗origin的訊息', window.location.origin);
                        window.opener.postMessage('來自子視窗azurestaticapps的訊息', 'https://purple-hill-0a0bd5710.4.azurestaticapps.net/'); // site-sub
                      } else {
                        console.error("No opener window found");
                      }
                      break
                    default:
                      console.warn("Unhandled case in switch statement")
                      break
                  }
                }
              }}
        />
        </Box>
    </React.Fragment>


  );
}

export default Game;
